import { Row, TableProps } from "react-table";

export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "1%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "Create Time",
      sortType: "datetime",
      accessor: "creationTime",
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].creationTime.toLocaleString();
        return dateString;
      },
    },
    {
      Header: "Store Number",
      accessor: "storeNumber",
    },
    {
      Header: "Banner",
      accessor: "banner",
    },
    {
      Header: "First Name",
      accessor: "firstName",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].firstName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].firstName}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].lastName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].lastName}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].email}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Province",
      accessor: "province",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Employee Number",
      accessor: "employeeNumber",
    },

    {
      Header: "Messages",
      accessor: "message",
    },
    {
      Header: "Language",
      accessor: "language",
    },
  ];
  return COLUMNS;
};
