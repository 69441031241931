import * as React from "react";
import { useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { PageLoader, Table } from "../../Components";
import { campaignClient } from "../../api";
import { DropZone, Loader, Text } from "@aws-amplify/ui-react";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { TableColumns } from "./RecipeCol";
import { ENV, S3HOST } from "../../helpers/utils";

export interface IRecipesProps {}

type formType = {
    recipeName: string;
    description: string;
    recipeName_fr: string;
    description_fr: string;

    img: { fileName: string; fileSize: number; url: string };
    pdf: { fileName: string; fileSize: number; url: string };
    imgFr: { fileName: string; fileSize: number; url: string };
    pdfFr: { fileName: string; fileSize: number; url: string };
};

type RecipeReturnType = {
    fileName: string;
    description: string;
    creationTime: string;
    fileSize: string;
    imageUrl: string;
    pdfUrl: string;
    recipeName: string;
    statsType: string;
    pdfUrlFr: string;
    imageUrlFr: string;
    fileNameFr: string;
};

export function Recipes(props: IRecipesProps) {
    const [isOpen, setIsOpen] = React.useState(false);

    const pdfInput = React.useRef<HTMLInputElement>(null);
    const imageInput = React.useRef<HTMLInputElement>(null);
    const pdfInputFr = React.useRef<HTMLInputElement>(null);
    const imageInputFr = React.useRef<HTMLInputElement>(null);

    const {
        isLoading,
        data: recipesData,
        refetch,
    } = useQuery("getRecipes", async () => {
        const res: { recipes: RecipeReturnType[] } = await campaignClient.call("getRecipes", {});
        return res.recipes;
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        clearErrors,
        formState: { errors, isDirty },
    } = useForm<formType>({
        mode: "onTouched",
        criteriaMode: "all",
        defaultValues: {
            recipeName: "",
            description: "",
            recipeName_fr: "",
            description_fr: "",
            img: { fileName: "", fileSize: 0, url: "" },
            pdf: { fileName: "", fileSize: 0, url: "" },
            imgFr: { fileName: "", fileSize: 0, url: "" },
            pdfFr: { fileName: "", fileSize: 0, url: "" },
        },
    });

    const deleteRecipe = React.useCallback(async (statsType: string) => {
        const { result } = await campaignClient.call<{ result: boolean }>("deleteRecipe ", {
            statsType,
        });
        if (result) {
            refetch();
        }
    }, []);

    const columns = React.useMemo(() => {
        if (recipesData) {
            return TableColumns({ deleteRecipe });
        }
    }, [recipesData]);

    const pdfFileMutation = useMutation(
        async (upload: { dataType: string; fileData: File }) => {
            const res = await campaignClient.call<{ uploadUrl: string; key: string }>("getFileUploadUrlForPublicHosting", {
                campaignKey: "3a541d23576f6ae6f468c39c62edb86d",
                filename: `${upload.dataType}/${upload.fileData.name}`,
                contentType: upload.fileData.type,
            });

            if (res && res.uploadUrl) {
                setValue("pdf", { fileName: upload.fileData.name, fileSize: upload.fileData.size, url: res.key });

                await axios.put(res.uploadUrl, upload.fileData, {
                    headers: {
                        "Content-Type": upload.fileData.type,
                    },
                });

                clearErrors("pdf.url");

                return res;
            }
        },
        {
            onSuccess(data) {},
            onError(error) {
                console.log(error);
            },
        }
    );
    const pdfFileMutationFr = useMutation(
        async (upload: { dataType: string; fileData: File }) => {
            const res = await campaignClient.call<{ uploadUrl: string; key: string }>("getFileUploadUrlForPublicHosting", {
                campaignKey: "3a541d23576f6ae6f468c39c62edb86d",
                filename: `${upload.dataType}/${upload.fileData.name}`,
                contentType: upload.fileData.type,
            });

            if (res && res.uploadUrl) {
                setValue("pdfFr", { fileName: upload.fileData.name, fileSize: upload.fileData.size, url: res.key });

                await axios.put(res.uploadUrl, upload.fileData, {
                    headers: {
                        "Content-Type": upload.fileData.type,
                    },
                });

                clearErrors("pdfFr.url");

                return res;
            }
        },
        {
            onSuccess(data) {},
            onError(error) {
                console.log(error);
            },
        }
    );

    const imageFileMutation = useMutation(
        async (upload: { dataType: string; fileData: File }) => {
            const res = await campaignClient.call<{ uploadUrl: string; key: string }>("getFileUploadUrlForPublicHosting", {
                campaignKey: "3a541d23576f6ae6f468c39c62edb86d",
                filename: `${upload.dataType}/${upload.fileData.name}`,
                contentType: upload.fileData.type,
            });

            if (res && res.uploadUrl) {
                setValue("img", { fileName: upload.fileData.name, fileSize: upload.fileData.size, url: res.key });

                await axios.put(res.uploadUrl, upload.fileData, {
                    headers: {
                        "Content-Type": upload.fileData.type as string,
                    },
                });
                clearErrors("img.url");
                return res;
            }
        },
        {
            onSuccess(data) {},
            onError(error) {
                console.log(error);
            },
        }
    );
    const imageFileMutationFR = useMutation(
        async (upload: { dataType: string; fileData: File }) => {
            const res = await campaignClient.call<{ uploadUrl: string; key: string }>("getFileUploadUrlForPublicHosting", {
                campaignKey: "3a541d23576f6ae6f468c39c62edb86d",
                filename: `${upload.dataType}/${upload.fileData.name}`,
                contentType: upload.fileData.type,
            });

            if (res && res.uploadUrl) {
                setValue("imgFr", { fileName: upload.fileData.name, fileSize: upload.fileData.size, url: res.key });

                await axios.put(res.uploadUrl, upload.fileData, {
                    headers: {
                        "Content-Type": upload.fileData.type as string,
                    },
                });
                clearErrors("imgFr.url");
                return res;
            }
        },
        {
            onSuccess(data) {},
            onError(error) {
                console.log(error);
            },
        }
    );

    const saveRecipeMutation = useMutation(
        async (recipe: {
            recipeName: string;
            description: string;
            recipeName_fr: string;
            description_fr: string;
            pdfUrl: string;
            imageUrl: string;
            statsType: string;
            fileName: string;
            fileSize: string;
            pdfUrlFr: string;
            imageUrlFr: string;
            fileNameFr: string;
        }) => {
            return campaignClient.call<{ result: boolean }>("saveRecipe", recipe);
        },
        {
            onSuccess(data) {
                reset();
                refetch();
                setTimeout(() => {
                    setIsOpen(false);
                }, 200);
            },
            onError(error) {
                console.log(error);
            },
        }
    );

    const onSubmit = handleSubmit((data) => {
        saveRecipeMutation.mutate({
            recipeName: data.recipeName,
            description: data.description,
            recipeName_fr: data.recipeName_fr,
            description_fr: data.description_fr,
            pdfUrl: data.pdf.url,
            imageUrl: data.img.url,
            pdfUrlFr: data.pdfFr.url,
            imageUrlFr: data.imgFr.url,
            statsType: window.crypto.randomUUID(),
            fileName: data.pdf.fileName,
            fileNameFr: data.pdfFr.fileName,

            fileSize: data.pdf.fileSize.toString(),
        });
    });

    if (isLoading) {
        return <PageLoader />;
    }

    return (
        <>
            <div className="main__head">
                <h2 className="main__title">Recipes</h2>
                <div className="main__actions">
                    <div className="search search--alt">
                        <div className="search__row search__row--input">
                            <button
                                className="btn__success"
                                onClick={() => {
                                    setIsOpen(!isOpen);
                                }}>
                                Add Recipe
                            </button>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>

            <div className="main__body">
                <div className="tabs js-tabs">
                    <div className="search search--alt search--medium main__search">
                        <div className="search__row">
                            <label htmlFor="q" className="hidden">
                                Search
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabs__body mt-5">
                <div className="table table-responsive">
                    {recipesData && columns && (
                        <Table
                            columns={columns}
                            data={recipesData ? recipesData : []}
                            tablePageSize={15}
                            //filterValue={filterTable}
                        />
                    )}
                </div>
            </div>
            <Modal show={isOpen} onHide={() => {}} centered>
                <form onSubmit={onSubmit}>
                    <Modal.Body style={{ padding: "20px" }}>
                        <h3>Add recipe</h3>
                        <div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">Recipe Name En</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("recipeName", {
                                        required: { value: true, message: "Please enter a name." },
                                    })}
                                />
                                {errors.recipeName && (
                                    <p className="error">
                                        <i className="fas fa-exclamation-circle" /> {errors.recipeName.message}
                                    </p>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">Recipe Name Fr</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("recipeName_fr", {
                                        required: { value: true, message: "Please enter a name." },
                                    })}
                                />
                                {errors.recipeName_fr && (
                                    <p className="error">
                                        <i className="fas fa-exclamation-circle" /> {errors.recipeName_fr.message}
                                    </p>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">Description En</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("description", {
                                        required: { value: true, message: "Please enter a description." },
                                    })}
                                />
                                {errors.description && (
                                    <p className="error">
                                        <i className="fas fa-exclamation-circle" /> {errors.description.message}
                                    </p>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">Description Fr</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("description_fr", {
                                        required: { value: true, message: "Please enter a description in French." },
                                    })}
                                />
                                {errors.description_fr && (
                                    <p className="error">
                                        <i className="fas fa-exclamation-circle" /> {errors.description_fr.message}
                                    </p>
                                )}
                            </div>
                            <div className="form-group" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                {" "}
                                <div className="form-group" style={{ display: "flex", flexDirection: "column", flexBasis: "49%" }}>
                                    <label htmlFor="exampleFormControlInput1">Upload recipe pdf - EN</label>
                                    {!watch("pdf").url ? (
                                        <DropZone
                                            onDropComplete={({ acceptedFiles, rejectedFiles }) => {
                                                pdfFileMutation.mutate({
                                                    fileData: acceptedFiles[0],
                                                    dataType: "recipe",
                                                });
                                            }}>
                                            {pdfFileMutation.isLoading ? (
                                                <Spinner />
                                            ) : (
                                                <>
                                                    <p>Drag recipe pdf here</p>
                                                    <button
                                                        className="btn__success"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            pdfInput && pdfInput.current && pdfInput.current.click();
                                                        }}>
                                                        Browse
                                                    </button>
                                                    <input
                                                        type="file"
                                                        tabIndex={-1}
                                                        className="hidden"
                                                        {...register("pdf.url", {
                                                            required: { value: true, message: "Please upload a pdf file." },
                                                        })}
                                                        ref={pdfInput}
                                                        accept="application/pdf"
                                                        onChange={(e) => {
                                                            if (e.target.files) {
                                                                pdfFileMutation.mutate({
                                                                    fileData: e.target.files[0],
                                                                    dataType: "recipe",
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </DropZone>
                                    ) : (
                                        <div style={{ display: "flex", textAlign: "center", margin: "20px", justifyContent: "center" }}>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <i className="fas fa-file-pdf" aria-hidden="true" style={{ color: "#d43316", fontSize: "35px" }} />
                                                <label className="form-label mt-2">
                                                    {watch("pdf").url ? (
                                                        <a style={{ lineBreak: "anywhere" }} href={`https://${S3HOST[ENV]}/${watch("pdf").url}`} target="_blank">{`${
                                                            watch("pdf").fileName
                                                        }`}</a>
                                                    ) : (
                                                        ""
                                                    )}
                                                </label>
                                            </div>

                                            <button onClick={() => setValue("pdf.url", "")} className="x-mark" style={{ minWidth: "20px" }}>
                                                X
                                            </button>
                                        </div>
                                    )}

                                    {errors.pdf && errors.pdf.url && (
                                        <p className="error">
                                            <i className="fas fa-exclamation-circle" /> Please upload a pdf file - EN.
                                        </p>
                                    )}
                                </div>
                                <div className="form-group" style={{ display: "flex", flexDirection: "column", flexBasis: "49%" }}>
                                    <label htmlFor="exampleFormControlInput1">Upload recipe pdf - FR</label>
                                    {!watch("pdfFr")?.url ? (
                                        <DropZone
                                            onDropComplete={({ acceptedFiles, rejectedFiles }) => {
                                                pdfFileMutationFr.mutate({
                                                    fileData: acceptedFiles[0],
                                                    dataType: "recipe",
                                                });
                                            }}>
                                            {pdfFileMutationFr.isLoading ? (
                                                <Spinner />
                                            ) : (
                                                <>
                                                    <p>Drag recipe pdf here</p>
                                                    <button
                                                        className="btn__success"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            pdfInputFr && pdfInputFr.current && pdfInputFr.current.click();
                                                        }}>
                                                        Browse
                                                    </button>
                                                    <input
                                                        type="file"
                                                        tabIndex={-1}
                                                        className="hidden"
                                                        {...register("pdfFr.url", {})}
                                                        ref={pdfInputFr}
                                                        accept="application/pdf"
                                                        onChange={(e) => {
                                                            if (e.target.files) {
                                                                pdfFileMutationFr.mutate({
                                                                    fileData: e.target.files[0],
                                                                    dataType: "recipe",
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </DropZone>
                                    ) : (
                                        <div style={{ display: "flex", textAlign: "center", margin: "20px", justifyContent: "center" }}>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <i className="fas fa-file-pdf" aria-hidden="true" style={{ color: "#d43316", fontSize: "35px" }} />
                                                <label className="form-label mt-2">
                                                    {watch("pdfFr").url ? (
                                                        <a style={{ lineBreak: "anywhere" }} href={`https://${S3HOST[ENV]}/${watch("pdfFr").url}`} target="_blank">{`${
                                                            watch("pdfFr").fileName
                                                        }`}</a>
                                                    ) : (
                                                        ""
                                                    )}
                                                </label>
                                            </div>

                                            <button onClick={() => setValue("pdfFr.url", "")} className="x-mark" style={{ minWidth: "20px" }}>
                                                X
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="form-group" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div className="form-group" style={{ display: "flex", flexDirection: "column", flexBasis: "49%" }}>
                                    <label htmlFor="exampleFormControlInput1">Upload recipe photo - EN</label>
                                    {!watch("img").url ? (
                                        <DropZone
                                            onDropComplete={({ acceptedFiles, rejectedFiles }) => {
                                                imageFileMutation.mutate({
                                                    fileData: acceptedFiles[0],
                                                    dataType: "recipe",
                                                });
                                            }}>
                                            {imageFileMutation.isLoading ? (
                                                <Spinner />
                                            ) : (
                                                <>
                                                    <p>Drag recipe photo here </p>
                                                    <button
                                                        className="btn__success"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            imageInput && imageInput.current && imageInput.current.click();
                                                        }}>
                                                        Browse
                                                    </button>
                                                    <input
                                                        type="file"
                                                        tabIndex={-1}
                                                        className="hidden"
                                                        {...register("img.url", {
                                                            required: true,
                                                        })}
                                                        ref={imageInput}
                                                        onChange={(e) => {
                                                            if (e.target.files) {
                                                                imageFileMutation.mutate({
                                                                    fileData: e.target.files[0],
                                                                    dataType: "recipe",
                                                                });
                                                            }
                                                        }}
                                                        accept="image/*"
                                                    />
                                                </>
                                            )}
                                        </DropZone>
                                    ) : (
                                        <div style={{ display: "flex", margin: "20px", alignItems: "center", justifyContent: "center" }}>
                                            <label className="form-label mt-2">
                                                {" "}
                                                <img src={`https://${S3HOST[ENV]}/${watch("img").url}`} className="img-thumbnail" height={180} width={180} />
                                            </label>
                                            <button onClick={() => setValue("img.url", "")} className="x-mark" style={{ minWidth: "20px" }}>
                                                X
                                            </button>
                                        </div>
                                    )}
                                    {errors.img && errors.img.url && (
                                        <p className="error">
                                            <i className="fas fa-exclamation-circle" />
                                            Please upload a image file.
                                        </p>
                                    )}
                                </div>
                                <div className="form-group" style={{ display: "flex", flexDirection: "column", flexBasis: "49%" }}>
                                    <label htmlFor="exampleFormControlInput1">Upload recipe photo - FR</label>
                                    {!watch("imgFr")?.url ? (
                                        <DropZone
                                            onDropComplete={({ acceptedFiles, rejectedFiles }) => {
                                                imageFileMutationFR.mutate({
                                                    fileData: acceptedFiles[0],
                                                    dataType: "recipe",
                                                });
                                            }}>
                                            {imageFileMutationFR.isLoading ? (
                                                <Spinner />
                                            ) : (
                                                <>
                                                    <p>Drag recipe photo here </p>
                                                    <button
                                                        className="btn__success"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            imageInputFr && imageInputFr.current && imageInputFr.current.click();
                                                        }}>
                                                        Browse
                                                    </button>
                                                    <input
                                                        type="file"
                                                        tabIndex={-1}
                                                        className="hidden"
                                                        {...register("imgFr.url", {})}
                                                        ref={imageInputFr}
                                                        onChange={(e) => {
                                                            if (e.target.files) {
                                                                imageFileMutationFR.mutate({
                                                                    fileData: e.target.files[0],
                                                                    dataType: "recipe",
                                                                });
                                                            }
                                                        }}
                                                        accept="image/*"
                                                    />
                                                </>
                                            )}
                                        </DropZone>
                                    ) : (
                                        <div style={{ display: "flex", margin: "20px", alignItems: "center", justifyContent: "center" }}>
                                            <label className="form-label mt-2">
                                                {" "}
                                                <img src={`https://${S3HOST[ENV]}/${watch("imgFr").url}`} className="img-thumbnail" height={180} width={180} />
                                            </label>
                                            <button onClick={() => setValue("imgFr.url", "")} className="x-mark" style={{ minWidth: "20px" }}>
                                                X
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            disabled={saveRecipeMutation.isLoading ? true : false}
                            type="button"
                            className="btn btn-primary small"
                            onClick={() => {
                                onSubmit();
                            }}>
                            Add recipe
                        </Button>
                        <button
                            type="button"
                            className="btn btn-secondary small outline"
                            onClick={() => {
                                // setImageLoaded("");
                                setIsOpen(false);
                            }}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
