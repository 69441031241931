import { Route, Navigate } from "react-router-dom";
import { NoMatch, Overview, Participants, Participant } from "../Pages";
import { Layout } from "../Components";
import Calendar from "../Pages/Calendar";
import SurprisePlus from "../Pages/SurprisePlus";
import SwipePlus from "../Pages/SwipePlus";
import { Recipes } from "../Pages/Recipes";

export default (
  <>
    <Route path="/" element={<Layout />}>
      <Route index element={<Navigate to="/overview" />} />
      <Route path="overview" element={<Overview />} />
      <Route path="participants" element={<Participants />} />
      <Route path="participant/:key" element={<Participant />} />
      <Route path="calendar" element={<Calendar />} />
      <Route path="swipeplus" element={<SwipePlus />} />
      <Route path="surpriseplus" element={<SurprisePlus />} />
      <Route path="recipes" element={<Recipes />} />
      <Route path="*" element={<NoMatch />} />
    </Route>
  </>
);
