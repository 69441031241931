import { Row, TableProps } from "react-table";

export const TableColumns = ({ deleteWinner }: { deleteWinner: Function }) => {
  const COLUMNS = [
    {
      Header: "First Name",
      accessor: "firstName",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
    },

    {
      Header: "Store Number",
      accessor: "storeNumber",
    },
    {
      Header: "Modified",
      accessor: "creationTime",
    },
    {
      Header: "Photo",
      accessor: "photoUrl",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].photoUrl ? (
          <img
            src={TableInfo.data[TableInfo.row.index].photoUrl}
            alt="photoUrl"
            width={40}
            height={40}
          />
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: " ",
      accessor: "delete",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].sessionKey ? (
          <button
            className="link-btn"
            onClick={() => deleteWinner(TableInfo.data[TableInfo.row.index].sessionKey)}>
            <img src="/assets/images/svg/trash.svg" alt="delete" />
          </button>
        ) : (
          "N/A"
        );
      },
    },
  ];
  return COLUMNS;
};
