export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "Banner",
      accessor: "banner",
    },
    {
      Header: "Number of entries",
      accessor: "entries",
    },
    {
      Header: "Number of stores",
      accessor: "stores",
    },
  ];
  return COLUMNS;
};
