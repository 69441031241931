import React, { useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useQuery } from "react-query";
import { campaignClient, publicCampaignClient } from "../../api";
import { PageLoader } from "../../Components";
import { showToast } from "../../Components/Toast/ToastManager";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

type FormEvent = { events: { eventName: string; eventNameFrench: string; eventDate: string }[] };

const Calendar = () => {
  const [loading, setLoading] = useState(false);
  const { isLoading: loadingEvents, refetch } = useQuery("getCalendar", async () => {
    const res: { events: any[] } = await publicCampaignClient.call("getCalendar", {});
    if (res?.events) {
      reset({
        events: res.events.sort((a, b) => (new Date(a.eventDate) > new Date(b.eventDate) ? 1 : -1)),
      });
    }
  });

  const {
    register,
    handleSubmit,
    getFieldState,
    watch,
    setValue,
    control,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm<FormEvent>({
    mode: "onTouched",
    criteriaMode: "all",
    defaultValues: { events: [{ eventName: "", eventDate: "", eventNameFrench: "" }] },
  });

  const {
    fields: eventFields,
    append: eventAppend,
    remove: eventRemove,
  } = useFieldArray({
    control,
    name: "events",
  });

  const handlesave = async (data: FormEvent) => {
    if (loading) {
      return;
    }
    setLoading(true);

    const result = await campaignClient.call("saveCalendar", { ...data });

    if (result) {
      showToast({
        content: "Saved",
        duration: 3000,
        error: false,
      });
      refetch();
      setLoading(false);
    }
  };

  if (loadingEvents || loading) {
    return <PageLoader />;
  }
  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Calendar of events</h2>
      </div>
      <div className="main__body main__body--flex main__body--flex-alt">
        <form onSubmit={handleSubmit(handlesave)} className="form">
          {eventFields.map((e, index) => (
            <div key={index} style={{ maxWidth: "65.8rem", marginBottom: "10px" }}>
              <div
                className="formContent"
                key={e.id}
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div className="form-group" style={{ display: "flex", flexDirection: "column" }}>
                  <label className="form__label" htmlFor="date">
                    Date
                  </label>
                  <Controller
                    control={control}
                    name={`events.${index}.eventDate`}
                    rules={{ required: { value: true, message: "Please select a date." } }}
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="MMMM dd,yyyy"
                        placeholderText="Select date"
                        className="form-control"
                        onChange={(date) => date && field.onChange(date?.toISOString())}
                        selected={field.value ? new Date(field.value) : null}
                      />
                    )}
                  />
                  {errors && errors.events && errors.events[index]?.eventDate && (
                    <div className="error">
                      <>
                        <i className="fas fa-exclamation-circle" />
                        {errors?.events[index]?.eventDate?.message}
                      </>
                    </div>
                  )}
                </div>
                <div className="form-group" style={{ minWidth: "30%" }}>
                  <label className="form__label" htmlFor="event Name">
                    Key date description EN
                  </label>
                  <Controller
                    control={control}
                    name={`events.${index}.eventName`}
                    rules={{ required: { value: true, message: "Please enter a description" } }}
                    render={({ field }) => (
                      <input
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        className="form-control"
                        placeholder="Key date description"
                      />
                    )}
                  />
                  {errors && errors.events && errors.events[index]?.eventName && (
                    <div className="error">
                      <>
                        <i className="fas fa-exclamation-circle" />
                        {errors?.events[index]?.eventName?.message}
                      </>
                    </div>
                  )}
                </div>

                <div className="form-group" style={{ minWidth: "30%" }}>
                  <label className="form__label" htmlFor="event Name">
                    Key date description FR
                  </label>
                  <Controller
                    control={control}
                    name={`events.${index}.eventNameFrench`}
                    rules={{ required: { value: true, message: "Please enter a description" } }}
                    render={({ field }) => (
                      <input
                        value={field.value}
                        onChange={(e) => field.onChange(e)}
                        className="form-control"
                        placeholder="Key date description"
                      />
                    )}
                  />
                  {errors && errors.events && errors.events[index]?.eventNameFrench && (
                    <div className="error">
                      <>
                        <i className="fas fa-exclamation-circle" />
                        {errors?.events[index]?.eventNameFrench?.message}
                      </>
                    </div>
                  )}
                </div>

                <button type="button" className="link-btn" onClick={() => eventRemove(index)}>
                  <img src="/assets/images/svg/minus-circle.svg" alt="delete" />
                </button>
              </div>
            </div>
          ))}
          <div className="form__foot">
            <ul className="list--buttons">
              <li>
                <button
                  className="btn__success"
                  type="submit"
                  disabled={watch().events.length < 0 ? true : false}>
                  Save changes
                </button>
              </li>

              <li>
                <button
                  type="button"
                  className="button__outline"
                  onClick={() =>
                    eventAppend({
                      eventName: "",
                      eventNameFrench: "",
                      eventDate: "",
                    })
                  }>
                  <i className="ico-plus-circle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 22 22">
                      <g
                        id="Icon_feather-plus-circle"
                        data-name="Icon feather-plus-circle"
                        transform="translate(-1 -1)">
                        <path
                          id="Path_12755"
                          data-name="Path 12755"
                          d="M22,12A10,10,0,1,1,12,2,10,10,0,0,1,22,12Z"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_12756"
                          data-name="Path 12756"
                          d="M12,8v8"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_12757"
                          data-name="Path 12757"
                          d="M8,12h8"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>
                  </i>
                  <span>Add Event</span>
                </button>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </>
  );
};

export default Calendar;
