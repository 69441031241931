import { Flex } from "@aws-amplify/ui-react";
import { defaultStorageHelper } from "@spry/campaign-client";
import React, { createRef, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { campaignClient, publicCampaignClient, sessionClient } from "../../api";
import { PageLoader, Table } from "../../Components";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { TableColumns } from "./WinnerCol";
import { filecolumns } from "./FilesCol";

type formtype = {
  firstName: string;
  lastName: string;
  storeNumber: String;
  photoUrl: string;
};
const SurprisePlus = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState<File>();
  const inputEl = useRef<HTMLInputElement | null>(null);
  const [filename, setFilename] = useState("");
  const [filetype, setFiletype] = useState("");
  const [imageUrl, setImageUrl] = useState(defaultStorageHelper.get("photoUrl") || "");
  const [photoKey, setPhotoKey] = useState("");
  const [uploading, setUploading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState<any>();
  const [selectedRows, setSelectedRows] = React.useState({});
  const handleSelection = React.useCallback((value: any) => {
    setSelectedRows(value);
  }, []);

  const {
    isLoading: isLoadingParticipants,
    data: participants,
    refetch,
  } = useQuery("getWinners", async () => {
    const res: { winners: any[] } = await campaignClient.call("getWinners", {});
    return res.winners;
  });
  const {
    isLoading: loadingFiles,
    data: files,
    refetch: refetchFiles,
  } = useQuery("getFiles", async () => {
    const res: { files: any[] } = await publicCampaignClient.call("getFiles", {
      dataType: "surprise",
    });
    return res.files;
  });

  const { isLoading: downloadinguserfiles, data: winnerPhotos } = useQuery(
    ["getuserfiles", participants],

    async () => {
      if (participants) {
        const urls = await Promise.all(
          participants.map(async (p) => {
            const res: { downloadUrls: { [key: string]: string } } =
              await publicCampaignClient.call("downloadUserFiles", { s3Key: p.metadata.photoUrl });
            return { sessionKey: p.sessionKey, url: res.downloadUrls[p.metadata.photoUrl] };
          })
        );
        return urls;
      } else {
        return [];
      }
    },
    { enabled: !!participants }
  );

  const data = useMemo(() => {
    if (participants && winnerPhotos && participants.length > 0) {
      return participants.map((participant: MainDbReturnTypes.Participant) => {
        return {
          firstName: participant.firstName,
          lastName: participant.lastName,
          creationTime: new Date(participant.creationTime).toLocaleString(),
          sessionKey: participant.sessionKey,
          storeNumber: participant.metadata?.storeNumber ? participant.metadata?.storeNumber : "NA",
          photoUrl: winnerPhotos.find((w) => w.sessionKey === participant.sessionKey)?.url,
        };
      });
    }
  }, [participants, winnerPhotos]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<formtype>({
    mode: "onTouched",
    criteriaMode: "all",
    defaultValues: { firstName: "", lastName: "", photoUrl: "", storeNumber: "" },
  });
  function handleSingleFile(data: any) {
    if (!data?.target?.files?.length) {
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", async () => {
      setImageLoaded(reader.result?.toString() || "");
    });
    reader.readAsDataURL(data.target.files[0]);
    setFile(data.target.files[0]);

    setFilename(data?.target?.files?.[0]?.name);
  }
  async function uploadPdf(event: any) {
    setUploading(true);

    const s3Key = await sessionClient.uploadFile({
      data: event.target.files[0],
      contentType: ".pdf",
    });
    if (s3Key) {
      const { result } = await campaignClient.call<{ result: boolean }>("saveFile", {
        key: s3Key,
        filename: event.target.files[0].name,
        filetype: event.target.files[0].type,
        fileSize: event.target.files[0].size,
        dataType: "surprise",
      });
      if (result) {
        setIsOpen(false);
        refetchFiles();
        setUploading(false);
      }
    }
  }
  async function handleUpload() {
    if (!file || uploading) {
      return;
    }
    setUploading(true);

    const fileToUpload = file;
    try {
      const data = await fileToUpload.arrayBuffer();
      const s3Key = await sessionClient.uploadFile({
        data,
        contentType: fileToUpload.type,
      });
      setPhotoKey(s3Key);
      return { key: s3Key, filename: fileToUpload.name, size: fileToUpload.size };
    } catch (e) {
      console.log(e);
    }
    setUploading(false);
  }
  const handlesave = async (data: formtype) => {
    const res = await handleUpload();
    if (res && res.key) {
      const { result } = await campaignClient.call<{ result: boolean }>("addWinner", {
        ...data,
        photoUrl: res.key,
        filename,
        filetype,
        fileSize: res.size,
      });
      if (result) {
        setIsOpen(false);
        refetch();
        setUploading(false);
      }
    }
  };
  const deleteWinner = async (sessionKey: string) => {
    if (uploading) {
      return;
    }
    setUploading(true);
    const { result } = await campaignClient.call<{ result: boolean }>("deleteWinner", {
      sessionKey,
    });
    if (result) {
      refetch();
      setUploading(false);
    }
  };
  const deleteFile = async (key: string) => {
    if (!key || uploading) {
      return;
    }
    setUploading(true);
    const result = await campaignClient.call("deleteFile", { key });
    if (result) {
      refetchFiles();
      setUploading(false);
    }
  };

  const columns = TableColumns({ deleteWinner });
  const fileColumns = filecolumns({ deleteFile });

  if (isLoadingParticipants || downloadinguserfiles || uploading || loadingFiles) {
    return <PageLoader />;
  }
  console.log(selectedRows)

  return (
    <>
      <div className="main__head" style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 className="main__title">Surprise+ - files</h2>
        <div>
          <button
            className="button__outline"
            disabled={Object.keys(selectedRows).length?false:true}
            onClick={async () => {
              if (files && selectedRows && Object.keys(selectedRows)) {
                const ids = Object.keys(selectedRows);
                await Promise.all(
                  ids.map(async (r) => {
                    await deleteFile(files[+r].key);
                  })
                );
              }
            }}>
            Delete
          </button>
          <button
            className="btn__success"
            disabled={uploading ? true : false}
            onClick={() => {
              if (inputEl !== null && inputEl.current !== null) {
                inputEl!.current!.click?.();
              }
            }}>
            {uploading ? "uploading" : "Upload file"}
          </button>
          <input
            style={{ display: "none" }}
            type="file"
            onChange={uploadPdf}
            id="upload"
            name="upload"
            accept="application/pdf"
            ref={inputEl}
          />
        </div>
      </div>
      <p>Please make sure file names consist of "Winners_EN" | "Winners_FR" |"Rules_FR" | "Rules_EN" to show appropriately in the website.</p>
      <div className="main__body main__body--flex main__body--flex-alt">
        <div className="tabs__body mt-5">
          <div className="table table--alt table--tabs table--big">
            {files && files?.length ? (
              <Table
                columns={fileColumns}
                data={files ? files : []}
                tablePageSize={15}
                checkbox={true}
                onSelectedRowsChange={handleSelection}
              />
            ) : (
              <p style={{ textAlign: "center" }}>There are no records to display</p>
            )}
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="main__head" style={{ display: "flex", justifyContent: "space-between" }}>
        <h2 className="main__title">Surprise+ - winners</h2>
        <div>
          <button
            className="button__outline"
            disabled={Object.keys(selectedRows).length?false:true}
            onClick={async () => {
              if (data && selectedRows && Object.keys(selectedRows)) {
                const ids = Object.keys(selectedRows);
                await Promise.all(
                  ids.map(async (r) => {
                    await deleteWinner(data[+r].sessionKey);
                  })
                );
              }
            }}>
            Delete
          </button>
          <button className="btn__success" onClick={() => setIsOpen(true)}>
            Add winner
          </button>
        </div>
      </div>
      <div className="main__body main__body--flex main__body--flex-alt">
        <div className="tabs__body mt-5">
          <div className="table table--alt table--tabs table--big">
            {data ? (
              <Table
                columns={columns}
                data={data ? data : []}
                tablePageSize={15}
                checkbox={true}
                onSelectedRowsChange={handleSelection}
              />
            ) : (
              <p style={{ textAlign: "center" }}>There are no records to display</p>
            )}
          </div>
        </div>
      </div>
      <Modal show={isOpen} onHide={() => {}} centered>
        <form onSubmit={handleSubmit(handlesave)}>
          <Modal.Body style={{ padding: "20px" }}>
            <h3>Add a winner</h3>
            <div>
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">First name</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("firstName", {
                    required: { value: true, message: "Please enter a first name." },
                  })}
                />
                {errors.firstName && (
                  <p className="error">
                    <i className="fas fa-exclamation-circle" /> {errors.firstName.message}
                  </p>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("lastName", {
                    required: { value: true, message: "Please enter a last name." },
                  })}
                />
                {errors.lastName && (
                  <p className="error">
                    <i className="fas fa-exclamation-circle" /> {errors.lastName.message}
                  </p>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Store number</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("storeNumber", {
                    required: { value: true, message: "Please enter a store number." },
                  })}
                />
                {errors.storeNumber && (
                  <p className="error">
                    <i className="fas fa-exclamation-circle" /> {errors.storeNumber.message}
                  </p>
                )}
              </div>
              <div className="form-group" style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="exampleFormControlInput1">Upload Photo</label>

                {imageLoaded ? (
                  <img src={imageLoaded} alt="winner-img" width={150} />
                ) : (
                  <input
                    type="file"
                    onChange={handleSingleFile}
                    className="form-control"
                    id="upload"
                    name="upload"
                    accept=".png, .jpg, .jpeg, capture=user"
                  />
                )}
                {!imageLoaded && (
                  <p className="error">
                    <i className="fas fa-exclamation-circle" /> Please upload an image.
                  </p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-primary small">
              Add winner
            </button>
            <button
              type="button"
              className="btn btn-secondary small outline"
              onClick={() => {
                setImageLoaded("");
                setIsOpen(false);
              }}>
              Cancel
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default SurprisePlus;
