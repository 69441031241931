import dayjs from "dayjs";
import _ from "lodash";

export function byKey<T>(records: T[], getKey: (t: T) => string) {
  return records.reduce((prev, curr) => {
    const key = getKey(curr);
    if (!prev[key]) {
      prev[key] = [];
    }
    prev[key].push(curr);
    return prev;
  }, {} as { [key: string]: T[] });
}

export const getDaysBetween2Dates = (startDate: string, endDate: string) => {
  let currentDate = dayjs(startDate);
  const date_array: string[] = [currentDate.toISOString()];

  while (currentDate.isBefore(dayjs(endDate))) {
    // console.log(currentDate);
    currentDate = dayjs(currentDate).add(1, "day");
    date_array.push(currentDate.toString());
  }
  return date_array;
};

export const getPastMonths = (past: number) => {
  let monthArray = [];
  let currentMonth = dayjs().format("MMM YY");
  do {
    monthArray.push(currentMonth);
    currentMonth = dayjs()
      .set("month", dayjs(currentMonth).month() - 1)
      .format("MMM YY");
  } while (monthArray.length < past);
  return monthArray;
};

export const emailRegex =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const looseNorthAmericanPhoneRegex =
  /^\+?1?[.() -]{0,2}[2-9][0-8][0-9][.() -]{0,2}[2-9][0-9]{2}[.() -]?[0-9]{4}$/;
export const canadianPostalCodeRegex = /^[a-z][0-9][a-z][ -]?[0-9][a-z][0-9]$/i;
export const americanZipCodeRegex = /^[0-9]{5}(-[0-9]{4})?$/;
export const stringRegex =
  /^[a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ \-']{2,64}$/i;

export function moveHashHeaderLast(columns: any[]) {
  // Find the index of the column with the "#" header
  const hashIndex = columns.findIndex((column) => column.Header === "#");
  // If the "#" header is not found, return the original array
  if (hashIndex === -1) {
    return columns;
  }
  // Remove the column with the "#" header and save it in a variable
  const hashColumn = columns.splice(hashIndex, 1)[0];
  // Push the column with the "#" header to the end of the array
  columns.push(hashColumn);
  // Return the modified array
  return columns;
}

export const validKeywords = [
  "address",
  "birthdate",
  "gender",
  "locale",
  "nickname",
  "phone_number",
  "picture",
  "preferred_username",
  "website",
  "zoneinfo",
];

export const removeWhiteSpacesForPassword = () => {
  // The below is the logic to remove white spaces from the password field.
  document.addEventListener("click", function (event) {
    const input = document.querySelector(
      "input[name='password']"
    ) as HTMLInputElement;
    if (event.target === input) {
      event.preventDefault();
    }
    input &&
      input.addEventListener("input", function () {
        const trimmedValue = _.trim(input.value);
        input.value = trimmedValue.replaceAll(" ", "");
      });
  });

  document.addEventListener("click", function (event) {
    const input = document.querySelector(
      "input[name='confirm_password']"
    ) as HTMLInputElement;
    if (event.target === input) {
      event.preventDefault();
    }
    input &&
      input.addEventListener("input", function () {
        const trimmedValue = _.trim(input.value);
        input.value = trimmedValue.replaceAll(" ", "");
      });
  });
};

export function formatSizeUnits(bytes:number){
  let temp
  if      (bytes>=1073741824) {temp=(bytes/1073741824).toFixed(2)+' GB';}
  else if (bytes>=1048576)    {temp=(bytes/1048576).toFixed(2)+' MB';}
  else if (bytes>=1024)       {temp=(bytes/1024).toFixed(2)+' KB';}
  else if (bytes>1)           {temp=bytes+' bytes';}
  else if (bytes===1)          {temp=bytes+' byte';}
  else                        {temp='0 byte';}
  return temp;
}

export const S3HOST: { [key:string]: string} =  {
  'dev': 'd2ztwr63umzbrb.cloudfront.net',
  'qa': 'd19r6gsb6bv553.cloudfront.net',
  'prod': 'd3tnfjs3dcqndn.cloudfront.net'
}

export const ENV = process.env.REACT_APP_ENV || "dev";

