import { Row, TableProps } from "react-table";
import { ENV, S3HOST } from "../../helpers/utils";
import dayjs from "dayjs";

export const TableColumns = ({ deleteRecipe }: { deleteRecipe: Function }) => {
    const COLUMNS = [
        {
            Header: "Recipe Name",
            accessor: "recipeName",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].recipeName ? (
                    <div className="w-25">
                        <p style={{ whiteSpace: "nowrap" }}>EN: {TableInfo.data[TableInfo.row.index].recipeName}</p>
                        <p style={{ whiteSpace: "nowrap" }}>FR: {TableInfo.data[TableInfo.row.index].recipeName_fr}</p>
                    </div>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].description ? (
                    <div className="w-25">
                        <p style={{ whiteSpace: "nowrap" }}>EN: {TableInfo.data[TableInfo.row.index].description}</p>
                        <p style={{ whiteSpace: "nowrap" }}>FR: {TableInfo.data[TableInfo.row.index].description_fr}</p>
                    </div>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Recipe photo",
            accessor: "imageUrl",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].imageUrl ? (
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "space-between", flexWrap: "wrap" }}>
                        {" "}
                        <img src={`https://${S3HOST[ENV]}/${TableInfo.data[TableInfo.row.index].imageUrl}`} alt="photoUrl" width={40} height={40} />
                        {TableInfo.data[TableInfo.row.index].imageUrlFr && (
                            <img src={`https://${S3HOST[ENV]}/${TableInfo.data[TableInfo.row.index].imageUrlFr}`} alt="photoUrl" width={40} height={40} />
                        )}
                    </div>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "PDF file",
            accessor: "pdfUrl",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].pdfUrl ? (
                    <div>
                        {" "}
                        <p>
                            <a style={{ lineBreak: "anywhere" }} href={`https://${S3HOST[ENV]}/${TableInfo.data[TableInfo.row.index].pdfUrl}`} target="_blank">{`${
                                TableInfo.data[TableInfo.row.index].fileName
                            }`}</a>
                        </p>
                        {TableInfo.data[TableInfo.row.index].pdfUrlFr && (
                            <p>
                                {" "}
                                <a style={{ lineBreak: "anywhere" }} href={`https://${S3HOST[ENV]}/${TableInfo.data[TableInfo.row.index].pdfUrlFr}`} target="_blank">{`${
                                    TableInfo.data[TableInfo.row.index].fileNameFr
                                }`}</a>
                            </p>
                        )}
                    </div>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Added",
            accessor: "creationTime",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].creationTime ? `${dayjs(TableInfo.data[TableInfo.row.index].creationTime).format("YYYY-MM-DD")}` : "N/A";
            },
        },
        {
            Header: " ",
            accessor: "statsType",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].statsType ? (
                    <button
                        className="link-btn"
                        onClick={() => {
                            deleteRecipe(TableInfo.data[TableInfo.row.index].statsType);
                        }}>
                        <img src="/assets/images/svg/trash.svg" alt="delete" />
                    </button>
                ) : (
                    "N/A"
                );
            },
        },
    ];
    return COLUMNS;
};
