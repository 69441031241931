import { Link } from "react-router-dom";
import { Row, TableProps } from "react-table";
import { formatSizeUnits } from "../../helpers/utils";

export const filecolumns = ({ deleteFile }: { deleteFile: Function }) => {
  const COLUMNS = [
    {
      Header: "Name",
      accessor: "filename",
      Cell: (TableInfo: TableProps) => {
        return <Link  className="link-more link-more--alt" to={TableInfo.data[TableInfo.row.index].url} >{TableInfo.data[TableInfo.row.index].filename}</Link>
      },
    },
    {
      Header: "Modified",
      accessor: "creationTime",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].creationTime
          ? new Date(TableInfo.data[TableInfo.row.index].creationTime).toLocaleString()
          : "N/A";
      },
    },

    {
      Header: "File size ",
      accessor: "fileSize",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].fileSize
          ? formatSizeUnits(TableInfo.data[TableInfo.row.index].fileSize)
          : "N/A";
      },

    },
    {
      Header: " ",
      accessor: "delete",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].key ? (
          <button
            className="link-btn"
            onClick={() => deleteFile(TableInfo.data[TableInfo.row.index].key)}>
            <img src="/assets/images/svg/trash.svg" alt="delete" />
          </button>
        ) : (
          "N/A"
        );
      },
    },
  ];
  return COLUMNS;
};
