import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import CustomLink from "../CustomLink";

function SideBar() {
  const [profile, setProfile] = useState("");

  const getProfile = async () => {
    let user = await Auth.currentAuthenticatedUser();
    setProfile(user.attributes.profile);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <nav className="nav sidebar__nav">
        <ul>
          {profile === "Standard" || profile === "Admin" ? (
            <CustomLink to="overview">
              <svg className="ico-bolt" viewBox="0 0 14 16" width="50">
                <use xlinkHref="/assets/images/svg/sprite.svg#ico-bolt" />
              </svg>
              <span>Overview</span>
            </CustomLink>
          ) : (
            <></>
          )}

          {profile === "Standard" || profile === "Admin" ? (
            <>
              <CustomLink to="participants">
                <svg className="ico-user">
                  <use xlinkHref="/assets/images/svg/sprite.svg#ico-user" />
                </svg>
                <span>Participants</span>
              </CustomLink>
              <CustomLink to="calendar">
                <img src="/assets/images/svg/ico-calendar.png" className="ico-user" alt="Swipe+" />

                <span>Calendar of events</span>
              </CustomLink>
              <CustomLink to="swipeplus">
                <img src="/assets/images/svg/swipe.svg" className="ico-user" alt="Swipe+" />
                <span>Swipe+</span>
              </CustomLink>
              <CustomLink to="surpriseplus">
                <img src="/assets/images/svg/surprise.svg" className="ico-user" alt="Surprise+" />
                <span>Surprise+</span>
              </CustomLink>
              <CustomLink to="Recipes">
                <img src="/assets/images/svg/icon-file.svg" className="ico-user" alt="Recipes" />
                <span>Recipes</span>
              </CustomLink>
            </>
          ) : (
            <></>
          )}

          {profile === "Admin" ? (
            <CustomLink to="manage">
              <svg className="ico-account2">
                <use xlinkHref="/assets/images/svg/sprite.svg#ico-account2" />
              </svg>
              <span>User Management</span>
            </CustomLink>
          ) : (
            <></>
          )}

          {/* <CustomLink to="winners">
            <svg className="ico-star">
              <use xlinkHref="/assets/images/svg/sprite.svg#ico-star" />
            </svg>
            <span>Winners</span>
          </CustomLink> */}

          {/* <CustomLink to="inventory">
            <svg className="ico-inventory">
              <use xlinkHref="/assets/images/svg/sprite.svg#ico-inventory" />
            </svg>

            <span>Inventory</span>
          </CustomLink> */}
        </ul>
      </nav>
    </>
  );
}

export default SideBar;
