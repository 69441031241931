export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "Date/Time",
      accessor: "creationTime",
      Cell: (TableInfo: any) => {
        return new Date(
          TableInfo.data[TableInfo.row.index].creationTime
        ).toLocaleString();
      },
    },
    {
      Header: "Info",
      Cell: (TableInfo: any) => {
        return TableInfo.data[TableInfo.row.index].tag &&
          TableInfo.data[TableInfo.row.index].tag === "navigation"
          ? `Navigated to /${
              TableInfo.data[TableInfo.row.index].metadata.path === "/"
                ? "landing"
                : TableInfo.data[TableInfo.row.index].metadata.path
                    .split("/")[1]
                    .split("/")[0]
            } page.`
          : TableInfo.data[TableInfo.row.index].tag ===
            "[api:addParticipantData]"
          ? "Participant data added."
          : TableInfo.data[TableInfo.row.index].tag === "instantwin"
          ? "Won instant gift."
          : TableInfo.data[TableInfo.row.index].tag ===
            "returning-matching-email"
          ? "Participant returned successfully."
          : TableInfo.data[TableInfo.row.index].tag === "emailsent"
          ? "Email resent by admin."
          : TableInfo.data[TableInfo.row.index].tag ===
            "returning-no-matching-email"
          ? "Participant returned unsuccessfully."
          : "";
      },
    },
  ];
  return COLUMNS;
};
