import { useMemo } from "react";
import { useQuery } from "react-query";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./OverviewCol";
import { campaignClient } from "../../api";


function Overview() {
  const { isLoading: isLoadingOverview, data: overview } = useQuery("getLogins", async () => {
    const res: any = await campaignClient.call("getLogins", {});
    return res.participants;
  });

  const getUniqueArr = (objects: []) => {
    const map = new Map(objects.map((pos: any) => [pos.banner, pos]));
    const uniques = [...map.values()];
    return uniques
  };

  const data = useMemo(() => {
    const result =
      overview &&
      overview.length > 0 &&
      overview.map((item: any) => {
        const entries = overview.filter((i: any) => i.banner === item.banner);
        const unique = [...new Set(entries.map((item: any) => item.storeNumber))];

        return {
          banner: item?.banner,
          stores: unique.length,
          entries: entries.length,
        };
      });
    return result && getUniqueArr(result);
  }, [overview]);

  const columns = TableColumns();

  if (isLoadingOverview) {
    return <PageLoader />;
  }

  return !isLoadingOverview && overview && overview.length > 0 ? (
    <>
      <div className="main__head">
        <h2 className="main__title">Overview</h2>
      </div>

      <div className="main__body main__body--flex main__body--flex-alt">
        <div className="boxes-info">
          <ul>
            <li>
              <div className="info-box">
                <p>Total entries</p>
                <h1>{data ? data.map((r: any) => r.entries).reduce((a: any, b: any

                ) => +a + +b, 0) : 0}</h1>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="tabs__body mt-5">
        <div className="table table--alt table--tabs table--big">
          <Table columns={columns} data={data ? data : []} tablePageSize={15} />
        </div>
      </div>
    </>
  ) : (
    <p>Currently there are no any data to display.</p>
  );
}

export default Overview;
