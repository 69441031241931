import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./ParticipantCol";
import { campaignClient, publicCampaignClient } from "../../api";
import { CSVLink } from "react-csv";

function Participants() {
  const [filterTable, setFilterTable] = useState("");

  const { isLoading: isLoadingParticipants, data: participants } = useQuery(
    "getParticipants",
    async () => {
      const res: MainDbReturnTypes.Participants = await campaignClient.call("getParticipants", {});
      return res.participants;
    }
  );

  const { isLoading: isLoadingSwipePlus, data: logins } = useQuery("getLogins", async () => {
    const res: any = await campaignClient.call("getLogins", {});
    return res.participants;
  });

  //Merge two arrays by sessionKey

  const data = useMemo(() => {
    if (logins && logins.length) {
      const filteredParticipants =
        participants &&
        participants.length > 0 &&
        participants.filter((i: any) => !i.metadata.type);
      const mergedParticipants = logins.reduce(
        (
          accum: [],
          current: {
            banner: string;
            dataKey: string;
            storeNumber: string;
          }
        ) => {
          const sameParticipant =
            filteredParticipants &&
            filteredParticipants?.length > 0 &&
            filteredParticipants.find((item: any) => item.sessionKey === current.dataKey);

          if (
            sameParticipant &&
            sameParticipant?.metadata?.messages &&
            sameParticipant?.metadata.messages?.length
          ) {
            const messages = sameParticipant.metadata.messages
              .map(({ message }: { message: string }) => message)
              .join("', '");

            const { firstName, lastName, email, phone, province, preferredLanguage } =
              sameParticipant;

            return [
              ...accum,
              {
                ...current,
                messages,
                firstName,
                lastName,
                email,
                phone,
                province,
                preferredLanguage,
                ...sameParticipant.metadata.messages[sameParticipant.metadata.messages?.length - 1],
              },
            ];
          } else {
            return [...accum, current];
          }
        },
        []
      );

      return (
        mergedParticipants &&
        mergedParticipants.map((participant: any) => {
          return {
            firstName: participant.firstName,
            lastName: participant.lastName,
            email: participant.email,
            creationTime: new Date(participant.creationTime),
            sessionKey: participant?.dataKey,
            province: participant?.province,
            phone: participant?.phone,
            language: participant?.preferredLanguage ? participant?.preferredLanguage : "en",
            employeeNumber: participant?.employeeNumber,
            message: participant?.messages,
            banner: participant?.banner,
            storeNumber: participant?.storeNumber,
          };
        })
      );
    }
  }, [participants, logins]);

  const reportData = useMemo(() => {
    if (data) {
      return data.map((rp: any) => {
        return {
          "Creation Time": rp.creationTime,
          "First Name": rp.firstName,
          "Last Name": rp.lastName,
          Email: rp.email,
          Province: rp.province,
          Phone: rp.phone,
          Language: rp.language,
          "Employee Number": rp.employeeNumber,
          Messages: rp.message,
          Banner: rp.banner,
          "Store Number": rp.storeNumber,
        };
      });
    }
  }, [data]);

  const columns = TableColumns();

  if (isLoadingParticipants || isLoadingSwipePlus) {
    return <PageLoader />;
  }

  return !isLoadingParticipants && participants && participants.length > 0 ? (
    <>
      <div className="main__head">
        <h2 className="main__title">Participants</h2>
        <div className="main__actions">
          <div className="search search--alt">
            <div className="search__row search__row--input">
              <CSVLink
                filename={`Campaign-Participants-Report-${new Date().toLocaleDateString()}`}
                className="btn btn--medium btn--mobile-small"
                data={reportData ? reportData : ""}
                asyncOnClick={true}
                target="_blank">
                Download Report
              </CSVLink>
            </div>
          </div>
        </div>
      </div>

      <div className="main__body">
        <div className="tabs js-tabs">
          <div className="search search--alt search--medium main__search">
            <div className="search__row">
              <label htmlFor="q" className="hidden">
                Search
              </label>
              <input
                type="search"
                name="q"
                id="q"
                value={filterTable}
                placeholder="Search"
                className="search__field"
                onChange={(e) => setFilterTable(e.target.value)}></input>
              <button type="submit" className="search__btn">
                <svg className="ico-search">
                  <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="tabs__body mt-5">
        <div className="table table--alt table--tabs table--big">
          <Table
            columns={columns}
            data={data ? data : []}
            tablePageSize={15}
            filterValue={filterTable}
          />
        </div>
      </div>
    </>
  ) : (
    <p>Currently there are no any participants to display.</p>
  );
}

export default Participants;
