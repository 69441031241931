import { useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./flowCol";
import { campaignClient } from "../../api";
import { byKey } from "../../helpers/utils";

function Participant() {
  const params = useParams();

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const [message, setMessage] = useState({ error: false, msg: "" });

  const [emailparams, setemailparams] = useState<any>();

  const { isLoading: isLoadingParticipant, data: matchedParticipant } = useQuery(
    "getParticipant",
    async () => {
      const res: MainDbReturnTypes.Participants = await campaignClient.call("getParticipants", {
        sessionKey: params.key,
      });

      return res.participants[0];
    }
  );

  const { isLoading: isLoadingPrizes, data: prizes } = useQuery("getPrizes", async () => {
    const res: MainDbReturnTypes.Prizes = await campaignClient.call("getPrizes", {});

    const prizes = byKey(res.prizes, (x) => x.prizeKey);

    return prizes;
  });

  const { isLoading: isLoadingFlows, data: flows } = useQuery("getFlows", async () => {
    const res: MainDbReturnTypes.Flows = await campaignClient.call("getFlows", {
      sessionKey: params.key,
    });

    return res.flows;
  });

  const formik = useFormik({
    initialValues: {
      // campaignKey: prizeWinners && prizeWinners[0] ? prizeWinners[0].campaignKey : '',
      sessionKey: matchedParticipant ? matchedParticipant.sessionKey : "",
      email: matchedParticipant ? matchedParticipant.email : "",
      firstName:
        matchedParticipant && matchedParticipant.firstName ? matchedParticipant.firstName : "",
      lastName: (matchedParticipant && matchedParticipant.lastName) || "",
      preferredLanguage: (matchedParticipant && matchedParticipant.preferredLanguage) || "en",
      province: (matchedParticipant && matchedParticipant.province) || "NA",

      banner:
        matchedParticipant &&
        matchedParticipant.metadata.messages &&
        matchedParticipant.metadata.messages[0].banner
          ? matchedParticipant.metadata.messages[0].banner
          : "NA",
      employeeNumber:
        matchedParticipant &&
        matchedParticipant.metadata.messages &&
        matchedParticipant.metadata.messages[0].employeeNumber
          ? matchedParticipant.metadata.messages[0].employeeNumber
          : "NA",
      storeNumber:
        matchedParticipant &&
        matchedParticipant.metadata.messages &&
        matchedParticipant.metadata.messages[0].storeNumber
          ? matchedParticipant.metadata.messages[0].storeNumber
          : "NA",
      messages:
        matchedParticipant &&
        matchedParticipant.metadata.messages &&
        matchedParticipant.metadata.messages[0].message
          ? matchedParticipant.metadata.messages
              .map(({ message }: { message: string }) => message)
              .join(", ")
          : "NA",
      phone: (matchedParticipant && matchedParticipant.phone) || "",
      //add more field initial values
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmit(true);

      const params = {
        sessionKey: matchedParticipant!.sessionKey,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        banner: values.banner,
        province: values.province,
        phone: values.phone,
        preferredLanguage: values.preferredLanguage,
        storeNumber: values.storeNumber,
        employeeNumber: values.employeeNumber,
        messages: values.messages,
      };

      try {
        const res = await campaignClient.call("addParticipantData", params);

        setIsSubmit(false);
      } catch (e) {
        setIsSubmit(false);
      }
    },
  });

  const handleClose = () => {
    setShowModal(false);
    setemailparams("");
  };

  if (isLoadingParticipant || isLoadingPrizes || isLoadingFlows) {
    return <PageLoader />;
  }
  async function handleRegister() {
    setIsSubmit(true);

    const params = {
      sessionKey: matchedParticipant!.sessionKey,
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      email: formik.values.email,
      banner: formik.values.banner,
      province: formik.values.province,
      phone: formik.values.phone,
      preferredLanguage: formik.values.preferredLanguage,
      storeNumber: formik.values.storeNumber,
      employeeNumber: formik.values.employeeNumber,
      messages: formik.values.messages,

      //add more field
      // postal: formik.values.postal
    };

    try {
      await campaignClient.call("addParticipantData", params);
      setIsSubmit(false);
      setMessage({ ...message, msg: "Successfull updated." });
      setTimeout(() => {
        setMessage({ error: false, msg: "" });
      }, 2000);
    } catch (e) {
      setIsSubmit(false);
    }
  }
  const columns = TableColumns();

  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Participant details</h2>

        <div className="main__actions">
          <a
            href="#"
            className="btn-back"
            onClick={(e) => {
              e.preventDefault();
              navigate("/participants");
            }}>
            <svg className="ico-arrow">
              <image xlinkHref="/assets/images/svg/ico-arrow.svg"></image>
            </svg>
          </a>
        </div>
      </div>
      <div className="main__body main__body--flex">
        <div className="main__form form">
          <form action="">
            <div className="form__body">
              <h4 className="form__title">Info</h4>

              <div className="form__row">
                <label htmlFor="firstName" className="form__label">
                  First name
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    id="firstName"
                    disabled={true}
                    {...formik.getFieldProps("firstName")}
                  />
                </div>
                {formik.touched.firstName && formik.errors.firstName && (
                  <p className="error-message">
                    <i className="fas fa-exclamation-circle" /> {formik.errors.firstName}
                  </p>
                )}
              </div>

              <div className="form__row">
                <label htmlFor="lastName" className="form__label">
                  Last name
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    id="lastName"
                    disabled={true}
                    {...formik.getFieldProps("lastName")}
                  />
                </div>
                {formik.touched.lastName && formik.errors.lastName && (
                  <p className="error-message">
                    <i className="fas fa-exclamation-circle" /> {formik.errors.lastName}
                  </p>
                )}
              </div>
              <div className="form__row">
                <label htmlFor="title" className="form__label">
                  Messages
                </label>

                <div className="form__controls">
                  <input
                    disabled={true}
                    type="text"
                    className="field"
                    {...formik.getFieldProps("messages")}
                    id="messages"
                  />
                </div>
              </div>

              <div className="form__row">
                <label htmlFor="email" className="form__label">
                  Email
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    {...formik.getFieldProps("email")}
                    id="email"
                    disabled={true}
                  />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <p className="error-message">
                    <i className="fas fa-exclamation-circle" /> {formik.errors.email}
                  </p>
                )}
              </div>

              <div className="form__row">
                <label htmlFor="title" className="form__label">
                  Phone
                </label>

                <div className="form__controls">
                  <input
                    disabled={true}
                    type="text"
                    className="field"
                    {...formik.getFieldProps("phone")}
                    id="phone"
                  />
                </div>
              </div>

              <div className="form__row">
                <label htmlFor="title" className="form__label">
                  Banner
                </label>

                <div className="form__controls">
                  <input
                    disabled={true}
                    type="text"
                    className="field"
                    {...formik.getFieldProps("banner")}
                    id="banner"
                  />
                </div>
              </div>
              <div className="form__row">
                <label htmlFor="title" className="form__label">
                  Store Number
                </label>

                <div className="form__controls">
                  <input
                    disabled={true}
                    type="text"
                    className="field"
                    {...formik.getFieldProps("storeNumber")}
                    id="store"
                  />
                </div>
              </div>

              <div className="form__row">
                <label htmlFor="title" className="form__label">
                  Employee Number
                </label>

                <div className="form__controls">
                  <input
                    disabled={true}
                    type="text"
                    className="field"
                    {...formik.getFieldProps("employeeNumber")}
                    id="employee"
                  />
                </div>
              </div>
              <div className="form__row">
                <label htmlFor="title" className="form__label">
                  Province
                </label>

                <div className="form__controls">
                  <input
                    disabled={true}
                    type="text"
                    className="field"
                    {...formik.getFieldProps("province")}
                    id="province"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="main__content">
          <div className="table table--functional">
            <div className="table__head">
              <h4 className="table__title">Recent flow activities</h4>
              <Table
                columns={columns}
                data={flows ? flows : []}
                tablePageSize={15}
                // filterValue={filterTable}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="alerts">
        <div
          className={`${message.msg ? "alert alert--wide is-shown" : "alert alert--wide "} `}
          id="alert-email">
          <img className="alert__icon" src="/assets/images/svg/ico-check-circle.svg" alt="" />

          <span>{message.msg}</span>

          <a href="#" className="alert__close js-alert-close">
            <svg className="ico-cross">
              <image xlinkHref="/assets/images/svg/ico-cross.svg" />
            </svg>
          </a>
        </div>
      </div>
    </>
  );
}

export default Participant;
